import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import LocalstorageService from "@/core/services/LocalstorageService";

const routes: Array<RouteRecordRaw> = [
  // dashboard
  {
    path: "/",
    redirect: "/dashboard",
    name: "dashboard",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      menu: true,
      menuId: "dashboard",
      title: "我的工作台"
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          svgIcon: "icon/svg/gen022.svg",
          heading: "儀錶板",
        }
      },
    ],
  },
  // 側邊欄(基本管理)
  {
    path: "/basicSettings",
    name: "basicSettings",
    component: () =>
      import("@/layout/Layout.vue"),
    meta: {
      menu: true,
      title: "基本管理",
      menuId: "basicSettings"
    },
    children: [
      // 空間設定
      {
        path: "spacesManagement",
        name: "spacesManagement",
        component: () =>
          import("@/views/pages/spacesManagement/SpacesManagement.vue"),
        meta: {
          sectionTitle: "空間設定",
          svgIcon: "icon/svg/Home.svg",
          fontIcon: "bi-archive",
        },
        children: [
          //基本資訊與設定
          {
            path: "spacesConfige",
            name: "spacesConfige",
            component: () =>
              import("@/views/pages/spacesManagement/SpacesConfige.vue"),
            meta: {
              heading: "基本資訊與設定",
            }
          },
          //管理者列表
          {
            path: "managerList",
            name: "managerList",
            component: () =>
              import("@/views/pages/spacesManagement/ManagerList.vue"),
            meta: {
              heading: "管理者列表",
            }
          },
          //帳單列表
          {
            path: "themePhoto",
            name: "themePhoto",
            component: () =>
              import("@/views/pages/spacesManagement/ThemePhoto.vue"),
            meta: {
              heading: "環境照片集",
            }
          },
          //價格方案列表
          {
            path: "chargePlanList",
            name: "chargePlanList",
            component: () =>
              import("@/views/pages/spacesManagement/chargePlan/ChargePlanList.vue"),
            meta: {
              heading: "價格方案列表",
            }
          },
          // 新增價格方案
          {
            path: "addChargePlan",
            name: "addChargePlan",
            component: () =>
              import("@/views/pages/spacesManagement/chargePlan/AddChargePlan.vue"),
            meta: {
              show: false,
              pageTitle: "新增價格方案"
            }
          },
           // 價格方案設定
           {
            path: "chargePlanSetUp/:chargePlanGuid",
            name: "chargePlanSetUp",
            component: () =>
              import("@/views/pages/spacesManagement/chargePlan/ChargePlanSetUp.vue"),
            meta: {
              show: false,
              pageTitle: "價格方案設定"
            }
          },
          // 價格方案-時間規則設定
          {
            path: "timeRuleSetUp/:chargePlanGuid",
            name: "timeRuleSetUp",
            component: () =>
              import("@/views/pages/spacesManagement/chargePlan/TimeRuleSetUp.vue"),
            meta: {
              show: false,
              pageTitle: "時間規則設定"
            }
          },
          //帳單設定
          {
            path: "billingSettings",
            name: "billingSettings",
            component: () =>
              import("@/views/pages/spacesManagement/BillingSettings.vue"),
            meta: {
              heading: "帳單設定",
            }
          },
          //通知設定
          {
            path: "noticeConfig",
            name: "noticeConfig",
            component: () =>
              import("@/views/pages/spacesManagement/NoticeConfig.vue"),
            meta: {
              heading: "通知設定",
            }
          }
        ]
      },
      // 設施設定
      {
        path: "facilityManagement",
        name: "facilityManagement",
        component: () =>
          import("@/views/pages/facilityManagement/FacilityManagement.vue"),
        meta: {
          sectionTitle: "設施設定",
          svgIcon: "icon/svg/Couch.svg",
        },
        children: [
          {
            path: "facilityList",
            name: "facilityList",
            component: () =>
              import("@/views/pages/facilityManagement/FacilityList.vue"),
            meta: {
              heading: "設施列表",
            },
          },
          {
            path: "facilityConfige/:facilityGuid",
            name: "facilityConfige",
            component: () =>
              import("@/views/pages/facilityManagement/FacilityConfige.vue"),
            meta: {
              show: false,
              pageTitle: "基本資訊與設定"
            },
            beforeEnter: (to, from, next) => {
              if (!from.name) {
                router.push({ name: 'facilityList' })
              } else {
                next()
              }
            }
          },
          {
            path: "facilityThemePhoto/:facilityGuid",
            name: "facilityThemePhoto",
            component: () =>
              import("@/views/pages/facilityManagement/ThemePhoto.vue"),
            meta: {
              show: false,
              pageTitle: "主題照片"
            },
            beforeEnter: (to, from, next) => {
              if (!from.name) {
                router.push({ name: 'facilityList' })
              } else {
                next()
              }
            }
          },
          {
            path: "seatList/:facilityGuid",
            name: "seatList",
            component: () =>
              import("@/views/pages/facilityManagement/SeatList.vue"),
            meta: {
              show: false,
              pageTitle: "座位列表"
            },
            beforeEnter: (to, from, next) => {
              if (!from.name) {
                router.push({ name: 'facilityList' })
              } else {
                next()
              }
            }
          },
          {
            path: "reserveList/:facilityGuid",
            name: "reserveList",
            component: () =>
              import("@/views/pages/facilityManagement/ReserveList.vue"),
            meta: {
              show: false,
              pageTitle: "特殊保留"
            },
            beforeEnter: (to, from, next) => {
              if (!from.name) {
                router.push({ name: 'facilityList' })
              } else {
                next()
              }
            }
          },
          {
            path: "addReserve/:facilityGuid",
            name: "addReserve",
            component: () =>
              import("@/views/pages/facilityManagement/AddReserve.vue"),
            meta: {
              show: false,
              pageTitle: "新增特殊保留"
            },
            beforeEnter: (to, from, next) => {
              if (!from.name) {
                router.push({ name: 'facilityList' })
              } else {
                next()
              }
            }
          }
        ]
      },
      // 設備設定
      {
        path: "deviceManagement",
        name: "deviceManagement",
        component: () =>
          import("@/views/pages/deviceManagement/DeviceManagement.vue"),
        meta: {
          sectionTitle: "設備設定",
          svgIcon: "icon/svg/Display3.svg",
        },
        children: [
          {
            path: "deviceList",
            name: "deviceList",
            component: () =>
              import("@/views/pages/deviceManagement/DeviceList.vue"),
            meta: {
              heading: "設備列表"
            }
          },
          {
            path: "deviceConfige/:deviceGuid",
            name: "deviceConfige",
            component: () =>
              import("@/views/pages/deviceManagement/DeviceConfige.vue"),
            meta: {
              show: false,
              pageTitle: "基本資訊與設定"
            },
            beforeEnter: (to, from, next) => {
              if (!from.name) {
                router.push({ name: 'deviceList' })
              } else {
                next()
              }
            }
          },
          {
            path: "deviceThemePhoto/:deviceGuid",
            name: "deviceThemePhoto",
            component: () =>
              import("@/views/pages/deviceManagement/ThemePhoto.vue"),
            meta: {
              show: false,
              pageTitle: "主題照片"
            },
            beforeEnter: (to, from, next) => {
              if (!from.name) {
                router.push({ name: 'deviceList' })
              } else {
                next()
              }
            }
          },
          {
            path: "itemList/:deviceGuid",
            name: "itemList",
            component: () =>
              import("@/views/pages/deviceManagement/ItemList.vue"),
            meta: {
              show: false,
              pageTitle: "品項列表"
            },
            beforeEnter: (to, from, next) => {
              if (!from.name) {
                router.push({ name: 'deviceList' })
              } else {
                next()
              }
            }
          },
        ]
      }
    ]
  },
  // 側邊欄(IoT設定)
  {
    path: "/IoTSettings",
    name: "IoTSettings",
    component: () =>
      import("@/layout/Layout.vue"),
    meta: {
      menu: true,
      title: "IoT 設定",
      menuId: "IoTSettings"
    },
    children: [
      // IoT管理
      {
        path: "IoTManagement",
        name: "IoTManagement",
        component: () =>
          import("@/views/pages/IoTManagement/IoTManagement.vue"),
        meta: {
          sectionTitle: "IoT 管理",
          svgIcon: "media/icons/metronic7/General/Settings-1.svg",
        },
        children: [
          // IoT 列表
          {
            path: "IoTList",
            name: "IoTList",
            component: () =>
              import("@/views/pages/IoTManagement/IoTList.vue"),
            meta: {
              heading: "IoT 列表",
            }
          },
          // 點位指令列表
          {
            path: "dataPointCommand/:deviceGuid/:deviceName",
            name: "dataPointCommand",
            component: () =>
              import("@/views/pages/IoTManagement/IoTDataPointCommand.vue"),
            meta: {
              show: false,
              pageTitle: "點位指令列表"
            }
          },
          // 裝置情境列表
          {
            path: "scenarioList/:deviceGuid",
            name: "scenarioList",
            component: () =>
              import("@/views/pages/IoTManagement/IoTScenarioList.vue"),
            meta: {
              show: false,
              pageTitle: "裝置情境列表"
            }
          },
          // 裝置對應
          {
            path: "mappingList/:deviceGuid",
            name: "mappingList",
            component: () =>
              import("@/views/pages/IoTManagement/IoTMappingList.vue"),
            meta: {
              show: false,
              pageTitle: "裝置對應"
            }
          },
          // 裝置排程
          {
            path: "scheduleList/:deviceGuid",
            name: "scheduleList",
            component: () =>
              import("@/views/pages/IoTManagement/IoTScheduleList.vue"),
            meta: {
              show: false,
              pageTitle: "裝置排程"
            }
          },
          // 詳細執行點位
          {
            path: "scenarioList/detailDataPointCommand/:deviceGuid/:scenarioGuid/:scenarioName",
            name: "detailDataPointCommand",
            component: () =>
              import("@/views/pages/IoTManagement/components/DetailDataPointCommand.vue"),
            meta: {
              show: false,
              pageTitle: "詳細執行點位"
            }
          },
          // 裝置對應列表
          {
            path: "IoTMappingList",
            name: "IoTMappingList",
            component: () =>
              import("@/views/pages/IoTManagement/IoTMappingListPage.vue"),
            meta: {
              heading: "IoT對應列表"
            }
          },
          // 新增裝置對應
          {
            path: "AddDeviceMapping",
            name: "AddDeviceMapping",
            component: () =>
              import("@/views/pages/IoTManagement/components/AddDeviceMapping.vue"),
            meta: {
              show: false,
              pageTitle: "新增裝置對應"
            }
          },
          // IoT 匯入
          {
            path: "IoTImport",
            name: "IoTImport",
            component: () =>
              import("@/views/pages/IoTManagement/IoTImport.vue"),
            meta: {
              heading: "IoT 匯入"
            }
          },
        ]
      },
      // 第三方主機管理
      {
        path: "thirdPartyServer",
        name: "thirdPartyServer",
        component: () =>
          import("@/views/pages/thirdPartyServer/ThirdPartyServer.vue"),
        meta: {
          sectionTitle: "第三方主機管理",
          svgIcon: "media/icons/metronic7/Devices/Server.svg",
        },
        children: [
          // 第三方主機列表
          {
            path: "ThirdPartyServerList",
            name: "ThirdPartyServerList",
            component: () =>
              import("@/views/pages/thirdPartyServer/ThirdPartyServerList.vue"),
            meta: {
              heading: "第三方主機列表"
            }
          }
        ]
      },
      // 門禁管理
      {
        path: "accessControl",
        name: "accessControl",
        component: () =>
          import("@/views/pages/accessControl/AccessControl.vue"),
        meta: {
          sectionTitle: "門禁管理",
          svgIcon: "media/icons/metronic7/Home/Door-open.svg",
        },
        children: [
          // 門禁列表
          {
            path: "accessDeviceList",
            name: "accessDeviceList",
            component: () =>
              import("@/views/pages/accessControl/AccessDeviceList.vue"),
            meta: {
              heading: "門禁裝置列表"
            }
          },
          // 通行紀錄
          {
            path: "passRecord",
            name: "passRecord",
            component: () =>
              import("@/views/pages/accessControl/PassRecord.vue"),
            meta: {
              heading: "通行紀錄"
            }
          },
        ]
      },
    ]
  },
  // 側邊欄(成員管理)
  {
    path: "/memberManagement",
    name: "memberManagement",
    component: () =>
      import("@/layout/Layout.vue"),
    meta: {
      menu: true,
      title: "成員管理",
      menuId: "memberManagement"
    },
    children: [
      // 成員
      {
        path: "memberSetting",
        name: "memberSetting",
        component: () =>
          import("@/views/pages/memberManagement/MemberSetting.vue"),
        meta: {
          sectionTitle: "成員設定",
          svgIcon: "media/icons/metronic7/Communication/Group.svg",
        },
        children: [
          {
            path: "memberList",
            name: "memberList",
            component: () =>
              import("@/views/pages/memberManagement/MemberList.vue"),
            meta: {
              heading: "成員列表",
            }
          },
          {
            path: "groupList",
            name: "groupList",
            component: () =>
              import("@/views/pages/memberManagement/GroupList.vue"),
            meta: {
              heading: "單位列表"
            }
          },
        ]
      },
      // 特殊通行管理
      {
        path: "specialAccess",
        name: "specialAccess",
        component: () =>
          import("@/views/pages/specialAccess/SpecialAccess.vue"),
        meta: {
          sectionTitle: "特殊通行管理",
          svgIcon: "media/icons/metronic7/General/Shield-check.svg",
        },
        children: [
           // 臉部辨識通行群組
           {
            path: "accessGroupList",
            name: "accessGroupList",
            component: () =>
              import("@/views/pages/specialAccess/AccessGroupList.vue"),
            meta: {
              heading: "臉部辨識通行群組"
            }
          },
          // 特殊通行列表
          {
            path: "specialAccessList",
            name: "specialAccessList",
            component: () =>
              import("@/views/pages/specialAccess/SpecialAccessList.vue"),
            meta: {
              heading: "特殊通行列表"
            }
          },
          // 權限設定
          {
            path: "permissionSettings",
            name: "permissionSettings",
            component: () =>
              import("@/views/pages/specialAccess/PermissionSettings.vue"),
            meta: {
              heading: "權限設定"
            }
          }
        ]
      }
    ]
  },
  // 側邊欄(例行服務)
  {
    path: "/routineService",
    name: "routineService",
    component: () =>
      import("@/layout/Layout.vue"),
    meta: {
      menu: true,
      title: "例行服務",
      menuId: "routineService"
    },
    children: [
      // 公告管理
      {
        path: "announcementManagement",
        name: "announcementManagement",
        component: () =>
          import("@/views/pages/announcementManagement/AnnouncementManagement.vue"),
        meta: {
          sectionTitle: "公告管理",
          svgIcon: "media/icons/metronic7/Communication/Clipboard-list.svg",
        },
        children: [
          {
            path: "announcementList",
            name: "announcementList",
            component: () =>
              import("@/views/pages/announcementManagement/AnnouncementList.vue"),
            meta: {
              heading: "公告列表",
            }
          },
          {
            path: "announcementHistoryList",
            name: "announcementHistoryList",
            component: () =>
              import("@/views/pages/announcementManagement/AnnouncementHistoryList.vue"),
            meta: {
              heading: "歷史公告列表"
            }
          },
          {
            path: "addAnnouncement",
            name: "addAnnouncement",
            component: () =>
              import("@/views/pages/announcementManagement/components/AddAnnouncement.vue"),
            meta: {
              show: false,
              pageTitle: "新增公告"
            }
          },
          {
            path: "editAnnouncement/:announcementGuid",
            name: "editAnnouncement",
            component: () =>
              import("@/views/pages/announcementManagement/components/EditAnnouncement.vue"),
            meta: {
              show: false,
              pageTitle: "編輯公告"
            }
          },
          {
            path: "viewAnnouncement/:announcementGuid",
            name: "viewAnnouncement",
            component: () =>
              import("@/views/pages/announcementManagement/components/ViewAnnouncementContent.vue"),
            meta: {
              show: false,
              pageTitle: "檢視公告"
            }
          },
        ]
      },
      // 代收管理
      {
        path: "collectionManagement",
        name: "collectionManagement",
        component: () =>
          import("@/views/pages/collectionManagement/CollectionManagement.vue"),
        meta: {
          sectionTitle: "代收管理",
          svgIcon: "media/icons/metronic7/General/Shield-check.svg",
        },
        children: [
          // 物品代收列表
          {
            path: "collectionList",
            name: "collectionList",
            component: () =>
              import("@/views/pages/collectionManagement/CollectionList.vue"),
            meta: {
              heading: "物品代收列表"
            }
          }
        ]
      },
      // 活動管理
      {
        path: "activityManagement",
        name: "activityManagement",
        component: () =>
          import("@/views/pages/activityManagement/ActivityManagement.vue"),
        meta: {
          sectionTitle: "活動管理",
          svgIcon: "media/icons/metronic7/Communication/Flag.svg",
        }
      },
      // 用戶使用評價
      {
        path: "userFeedback",
        name: "userFeedback",
        component: () =>
          import("@/views/pages/userFeedback/UserFeedback.vue"),
        meta: {
          sectionTitle: "用戶使用評價",
          svgIcon: "media/icons/metronic7/Communication/Chat5.svg",
        },
        children: [
          // 用戶評價列表
          {
            path: "userFeedbackList",
            name: "userFeedbackList",
            component: () =>
              import("@/views/pages/userFeedback/UserFeedbackList.vue"),
            meta: {
              heading: "用戶評價列表"
            }
          }
        ]
      },
    ]
  },
  // 側邊欄(財務處理)
  {
    path: "/financialHandle",
    name: "financialHandle",
    component: () =>
      import("@/layout/Layout.vue"),
    meta: {
      menu: true,
      title: "財務處理",
      menuId: "financialHandle"
    },
    children: [
      // 訂單管理
      {
        path: "orderManagement",
        name: "orderManagement",
        component: () =>
          import("@/views/pages/orderManagement/OrderManagement.vue"),
        meta: {
          sectionTitle: "訂單管理",
          svgIcon: "media/icons/metronic7/General/Clipboard.svg",
        },
        children: [
          {
            path: "orderList",
            name: "orderList",
            component: () =>
              import("@/views/pages/orderManagement/OrderList.vue"),
            meta: {
              heading: "訂單列表",
            }
          },
          {
            path: "billList",
            name: "billList",
            component: () =>
              import("@/views/pages/orderManagement/billList.vue"),
            meta: {
              heading: "帳單列表"
            }
          },
        ]
      },
      // 優惠管理
      {
        path: "couponManagement",
        name: "couponManagement",
        component: () =>
          import("@/views/pages/couponManagement/CouponManagement.vue"),
        meta: {
          sectionTitle: "優惠管理",
          svgIcon: "media/icons/metronic7/General/Shield-check.svg",
        },
        children: [
          // 綁定代碼列表
          {
            path: "couponBindCodeList",
            name: "couponBindCodeList",
            component: () =>
              import("@/views/pages/couponManagement/CouponBindCodeList.vue"),
            meta: {
              heading: "綁定代碼列表"
            }
          },
          // 優惠券列表
          {
            path: "couponList",
            name: "couponList",
            component: () =>
              import("@/views/pages/couponManagement/CouponList.vue"),
            meta: {
              heading: "優惠券列表"
            }
          }
        ]
      },
      // 合約管理
      {
        path: "contractManagement",
        name: "contractManagement",
        component: () =>
          import("@/views/pages/contractManagement/ContractManagement.vue"),
        meta: {
          sectionTitle: "合約管理",
          svgIcon: "media/icons/metronic7/Communication/Mail-opened.svg",
        },
        children: [
          // 合約範本列表
          {
            path: "contractList",
            name: "contractList",
            component: () =>
              import("@/views/pages/contractManagement/ContractList.vue"),
            meta: {
              heading: "合約範本列表"
            }
          },
          // 合約優惠列表
          {
            path: "contractOfferList",
            name: "contractOfferList",
            component: () =>
              import("@/views/pages/contractManagement/ContractOfferList.vue"),
            meta: {
              heading: "合約優惠列表"
            }
          },
          // 新增範本
          {
            path: "addContractTemplate",
            name: "addContractTemplate",
            component: () =>
              import("@/views/pages/contractManagement/AddContractTemplate.vue"),
            meta: {
              show: false,
              pageTitle: "新增合約範本"
            }
          },
          // 修改範本
          {
            path: "updateContractTemplate/:contractTemplateGuid",
            name: "updateContractTemplate",
            component: () =>
              import("@/views/pages/contractManagement/UpdateContractTemplate.vue"),
            meta: {
              show: false,
              pageTitle: "檢視與修改合約範本"
            }
          },
        ]
      },
    ]
  },
  // 側邊欄(報表與分析)
  {
    path: "/analysisReport",
    name: "analysisReport",
    component: () =>
      import("@/layout/Layout.vue"),
    meta: {
      menu: true,
      title: "報表與分析",
      menuId: "analysisReport"
    },
    children: [
      // 報表
      {
        path: "report",
        name: "report",
        component: () =>
          import("@/views/pages/report/Report.vue"),
        meta: {
          sectionTitle: "報表",
          svgIcon: "media/icons/metronic7/Layout/Layout-top-panel-5.svg",
        },
        children: [
          // 未開放
          {
            path: "notOpen",
            name: "notOpen",
            component: () =>
              import("@/views/pages/report/NotOpen.vue"),
            meta: {
              heading: "未開放"
            }
          }
        ]
      }
    ]
  },
  // 登入
  {
    path: "/",
    component: () => import("@/components/page-layouts/AuthBothSides.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/authentication/basic-flow/SignInBothSides.vue"),
      }
    ],
  },
  // 404
  {
    // 404 路由，當以上都不匹配時
    path: "/404",
    name: "404",
    component: () => import("@/views/authentication/Error404.vue"),
  },
  // 404
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from) => {

  if (!isLogged() && to.name != "sign-in") {

    router.push({ name: "sign-in" });

  } else {

    // 設置麵包屑
    setBreadcrumbs(to)

    // 設置網頁標題
    setWebTitle(to)
    
  }


  // 將配置重置為初始狀態
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

// 設置麵包屑
function setBreadcrumbs(to) {
  const breadcrumbs: Array<string> = []
  for (let index = 0; index < to.matched.length; index++) {
    if (to.matched[index].meta.title !== undefined) {
      breadcrumbs.push(
        to.matched[index].meta.title
      )
    } else if (to.matched[index].meta.sectionTitle !== undefined) {
      breadcrumbs.push(
        to.matched[index].meta.sectionTitle
      )
    }
  }
  if (to.meta.show !== false) {
    setCurrentPageBreadcrumbs(`${to.meta.heading}`, breadcrumbs);
  } else {
    setCurrentPageBreadcrumbs(`${to.meta.pageTitle}`, breadcrumbs);
  }
}

// 設置網頁標題
function setWebTitle(to) {
  if (to.name == "sign-in") {
    document.title = "Login - Spaces Go Portal";
  } else {
    const currentSpaceName = LocalstorageService.getCurrentSpaces()!.space.name
    document.title = `${currentSpaceName} - Spaces Go Portal`;
  }
}

// 判斷是否登入
function isLogged() {
  return store.getters.isUserAuthenticated
}

export default router;
