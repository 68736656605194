import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = "https://spacesgoapi.azurewebsites.net/";
    // ApiService.vueInstance.axios.defaults.baseURL = "https://localhost/";
    ApiService.setInterceptor();
    ApiService.setHeader();
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common["X-APPLICATION"] = 'SpacesPortal';
    ApiService.vueInstance.axios.defaults.headers.common[
      "X-APPLICATION"
    ] = "SpacesPortal";
    ApiService.vueInstance.axios.defaults.headers.common[
      "X-PLATFORM"
    ] = "chrome";
    ApiService.vueInstance.axios.defaults.headers.common[
      "X-API-KEY"
    ] = "apiKey";
    ApiService.vueInstance.axios.defaults.headers.common[
      "accept"
    ] = "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios
      .get(`${resource}/${slug}`)
      .catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  
  /**
 * @description Send the patch HTTP request
 * @param resource: string
 * @param params: AxiosRequestConfig
 * @returns Promise<AxiosResponse>
 */
   public static patch(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.patch(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }

  private static setInterceptor() {
    ApiService.vueInstance.axios.interceptors.request.use(
      config => {
        // 取得jwtService中的token 若有值則表示登入過
        const hasToken = JwtService.getToken();
        
        // 當有登入過時自動加上所需欄位
        if (hasToken) {
          // 若登入過  則將SID 自動帶入Header欄位
          // config.headers.common["X-SID"] = JwtService.getToken();

          config.headers["X-USER-GUID"]= JwtService.getToken();
        }
        return config;
      },
      error => {
        // Do something with request error
        Promise.reject(error);
      }

    );
    // ApiService.vueInstance.axios.interceptors.response.use(
    //   /**
    //    * If you want to get http information such as headers or status
    //    * Please return  response => response
    //    */

    //   /**
    //    * Determine the request status by custom code
    //    * Here is just an example
    //    * You can also judge the status by HTTP Status Code
    //    */
    //   response => {
    //     const res = response
    //     if (response.status === 200) {
    //       return res
    //     } else {
    //       return res
    //     }
    //   },
    //   error => {
    //     // if (error.response) {
    //     const errorCode = error.response.data.errors[0].errorCode
    //     // const message = error.response.data.errors[0].errorMessage
    //    console.log("error",error.response)
    //     return Promise.reject(error);
    //   }
    // )
  }
}

export default ApiService;
