import { createI18n } from "vue-i18n";

const messages = {
  en: {
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
  },
  zh_tw:
  {
    AsusCloud: "華碩",
    Ardomus: "宇曜智能",
    AetherService: "艾維特",
    Plug: "插座",
    LightSwitch: "燈",
    AirConditioner: "冷氣",
    DoorLock: "門鎖",
    LightGroup: "群燈(廣播)",
    AirPurifier: "空氣清淨機",
    Circuit: "迴路",
    ControlButton: "控制鈕",
    MotorizedBlinds: '電動百葉窗',
    dashboard: "Dashboard",
    AliveStatus: "存活狀態",
    Power: "電源狀態",
    AirMode: "冷氣模式",
    AirFanSpeed: "冷氣風速",
    AirTemperatureSetting: "冷氣溫度設定",
    AirTemperatureIndoor: "冷氣室內溫度",
    BatteryPercentage: "電池",
    LightLevel: "亮度",
    LightColorTemperature: "色溫",
    AirPanelLock: "冷氣面板控制",
    MoveUp: "向上轉動",
    MoveDown: "向下轉動",
    MoveStop: "窗簾停止",
    MoveToPosition: "移動至指定位置",
    CurrentPosition: "當前位置",
    UnKnow: "未知",
    Online: "線上",
    Offline: "離線",
    On: "電源開啟",
    Off: "電源關閉",
    Locked: "上鎖",
    UnLocked: "解鎖",
    AirCondition: "冷氣",
    Dehumidify: "除溼",
    Ventilation: "送風",
    Automatic: "自動",
    Heating: "暖氣",
    Manual: "手動",
    PanelUnLocked: "面板未鎖定",
    PanelLocked: "面板鎖定",
    StandBy: "等待",
    Down: "向下",
    Up: "向上",
    Stop: "停止",
  }
};

const i18n = createI18n({
  legacy: false,
  locale: "zh_tw",
  globalInjection: true,
  messages,
});

export default i18n;
