import { Spaces } from "@/core/services/responsesModel/spaces"

// 自訂local storage
const SPACES = "spaces";
const CURRENTSPACES = "currentSpaces";
// Spaces
export const getSpaces = (): Array<Spaces> => {
  if (!window.localStorage.getItem(SPACES)) {
    return []
  }
  return JSON.parse(window.localStorage.getItem(SPACES)!);
};


export const saveSpaces = (spaces: Array<Spaces>) => {
  window.localStorage.setItem(SPACES, JSON.stringify(spaces));
};

export const destroySpaces = () => {
  window.localStorage.removeItem(SPACES);
};

// currentSpaces
export const getCurrentSpaces = (): Spaces | null => {
  if (!window.localStorage.getItem(CURRENTSPACES)) {
    return null
  }
  return JSON.parse(window.localStorage.getItem(CURRENTSPACES)!);
};

export const saveCurrentSpaces = (currentSpaces: Spaces) => {
  window.localStorage.setItem(CURRENTSPACES, JSON.stringify(currentSpaces));
};

export const destroyCurrentSpaces = () => {
  window.localStorage.removeItem(CURRENTSPACES);
};


export default {
  getSpaces, saveSpaces, destroySpaces,
  getCurrentSpaces, saveCurrentSpaces, destroyCurrentSpaces
};
