import ApiService from "@/core/services/ApiService";
import { Spaces } from "@/core/services/responsesModel/spaces"

export function getSpacesByManager(): Promise<Array<Spaces>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Spaces/QueryByManager`, {}).then(res => {
            resolve(res.data.spaces)
        }).catch(err => {
            reject(err)
        })
    })
}

export function updateSpace(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Spaces/UpdateData`, request).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// 更新空間設施樣板設定
export function updateFacilitySetting(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Spaces/UpdateFacilitySetting`, request).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}